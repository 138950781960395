<template>
  <el-dialog
    :title="tipsObj.title"
    class="tips"
    :visible.sync="dialogVisible"
    append-to-body
    :close-on-click-modal="false"
    width="35%"
  >
    <div class="content">
      <div class="flex items-center font-bold">
        <i class="el-icon-delete text-danger" v-if="tipsObj.showIcon"></i>
        <span class="m-l-10">{{ tipsObj.tipsHead }}</span>
      </div>
      <div class="mt-8 m-l-10 textColor09">{{ tipsObj.tipsContent }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSure"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "TipsBox",
  props: {
    tipsObj: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    //确 定
    handleSure() {
      this.$emit("sure", this.tipsObj.type);
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  /deep/.el-dialog__footer {
    text-align: right;
  }
  .content {
    padding: 10px;
    .tips-head {
      font-weight: bold;
      margin-left: 10px;
    }
  }
}
</style>
