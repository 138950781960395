<template>
  <el-dialog
    title="邀请加盟"
    class="inviteJoin"
    :visible.sync="dialogVisible"
    append-to-body
    :close-on-click-modal="false"
    width="70%"
  >
    <div class="content">
      <InviteJoin
        :excludeAllianceId="excludeAllianceId"
        :isReset="isReset"
        @change="membersChange"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSure"
        >确认邀请</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import InviteJoin from "./InviteJoin.vue";

export default {
  name: "InviteJoinBox",
  components: {
    InviteJoin,
  },
  props: {
    excludeAllianceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
      isReset: false,
      memberIds: [],
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
      this.isReset = true;
    },
    membersChange(val) {
      this.memberIds = val;
    },
    //确认邀请
    handleSure() {
      console.log("确认邀请");
      this.$emit("sure", this.memberIds);
    },
  },
};
</script>

<style lang="less" scoped>
.inviteJoin {
  /deep/.el-dialog__footer {
    text-align: right;
  }
  .area {
    width: 200px !important;
  }
  .table-bottom {
    text-align: right;
    margin-top: @margin-size-secondary;
  }
}
</style>
