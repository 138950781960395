<template>
  <el-dialog
    title="移交联盟管理员身份"
    class="transAllianceAdmin"
    :visible.sync="dialogVisible"
    append-to-body
    :close-on-click-modal="false"
    width="35%"
  >
    <div class="content">
      <div class="text-center mb-8">选择联盟成员</div>
      <div class="add_box">
        <div class="search">
          <Search
            :formData="fromObj"
            placeholderText="商户名称/联系人/联系电话"
            @handleQuery="handleQuery"
          >
          </Search>
        </div>

        <div class="body">
          <!-- <el-radio-group v-model="radio">
            <el-radio
              :label="item.id"
              v-for="(item, index) in membersList"
              :key="index"
            >
              {{ item.name }}/{{ item.contacts }}/{{ item.phone }}
            </el-radio>
          </el-radio-group> -->

          <div
            class="item"
            v-for="(item, index) in membersList"
            :key="index"
            @click="selectItem(item)"
          >
            <div>{{ item.name }}/{{ item.contacts }}/{{ item.phone }}</div>
            <span
              :class="['radio__inner', item.checked ? 'is-checked' : '']"
            ></span>
          </div>
          <div class="noData" v-if="!membersList.length">
            暂无数据
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSure">
        确认移交
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import Search from "./Search.vue";
import {
  getAllianceMembersList, //联盟成员列表
} from "@/api/alliance";

export default {
  name: "TransAllianceAdminBox",
  components: {
    Search,
  },
  props: {
    excludeAllianceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
      fromObj: {
        keyword: "",
      },
      membersList: [],
      selectObj: {},
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    //确认移交
    handleSure() {
      this.$emit("sure", this.selectObj.memberId);
    },
    //搜索
    handleQuery() {
      let params = {
        id: this.excludeAllianceId,
        keyword: this.fromObj.keyword,
      };
      getAllianceMembersList(params).then((res) => {
        this.membersList = res?.rows || [];
      });
    },
    selectItem(item) {
      console.log("item", item);
      this.membersList.forEach((p) => {
        if (p.id == item.id) {
          p.checked = true;
          this.selectObj = p;
        } else {
          p.checked = false;
        }
      });
      console.log("membersList", this.membersList);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="less" scoped>
.transAllianceAdmin {
  /deep/.el-dialog__footer {
    text-align: right;
  }
  .content {
    padding: 10px;
    // display: flex;
    // align-items: center;
    // justify-content: space-around;
    .add_box {
      height: 326px;
      border: 1px solid #c6c6c6;
      display: flex;
      flex-direction: column;
      .search {
        border-bottom: 1px solid #c6c6c6;
      }
      .body {
        flex: 1;
        padding: 0 10px;
        overflow: auto;
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          border-bottom: 1px solid #c6c6c6;
          cursor: pointer;
          .radio__inner {
            border: 1px solid #dcdfe6;
            border-radius: 100%;
            width: 14px;
            height: 14px;
            background-color: #fff;
            position: relative;
            cursor: pointer;
            display: inline-block;
            box-sizing: border-box;
            &::after {
              width: 4px;
              height: 4px;
              border-radius: 100%;
              background-color: #fff;
              content: "";
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) scale(1);
              transition: transform 0.15s ease-in;
            }
          }
          .is-checked {
            border-color: #409eff;
            background: #409eff;
          }
        }
        .noData {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #999;
        }
      }
    }
  }
  .table-bottom {
    text-align: right;
    margin-top: @margin-size-secondary;
  }
}
</style>
